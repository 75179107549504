import React, { useMemo } from 'react';

import { LoadableLandingSearchForm } from './LoadableLandingSearchForm';
import { LoadableMainImage } from './LoadableMainImage';
import { HeroBannerProps } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';

export const HeroBanner: React.FC<HeroBannerProps & ClassNameProps> = ({
  cheapestPricePence,
  image,
  title,
  searchConfiguration,
  showSearchForm,
  showMainImage,
}) => {
  const constrainedHeroImage = useFeatureFlag('ConstrainedHeroImage');

  return useMemo(
    () => (
      <div
        sx={{
          display: 'flex',
          flexDirection: ['column', 'column-reverse'],
        }}
      >
        {showMainImage && image && (
          <LoadableMainImage
            image={image}
            title={title}
            cheapestPricePence={cheapestPricePence}
            sx={{
              ...(!showSearchForm && { marginTop: ['s', 0] }),
            }}
          />
        )}

        {showSearchForm && (
          <LoadableLandingSearchForm
            searchConfiguration={searchConfiguration}
            sx={{
              ...(constrainedHeroImage && { marginBottom: [null, 's'] }),
            }}
          />
        )}
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [constrainedHeroImage],
  );
};

// eslint-disable-next-line import/no-default-export
export default HeroBanner;
